import React from 'react'
import Layout from '../../components/layout'
import { GroupingCreator } from '../../components/ChallengeCreators'

const GroupingCreatorPage = ({ challengeId }) => {
  return <Layout>
    <GroupingCreator challengeId={challengeId} />
  </Layout>
}

export default GroupingCreatorPage
